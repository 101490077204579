section.passwordReset {
    background: linear-gradient(164deg, rgb(180 180 58) 0%, rgb(29 88 253) 50%, rgba(252, 176, 69, 1) 100%);
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
}

.passwordReset form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
}

.passwordReset form p {
    width: 90%;
    min-width: 50vw;
}

.passwordReset h1 {
    margin-bottom: 1em;
}

.passwordReset h1+p {
    margin-bottom: 1em;
}

.passwordReset input {
    margin: 0.5em 1em 1em;
    width: 90%;
    min-width: 50vw;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.passwordReset button {
    width: auto;
    padding: 0.5em 1em;
    margin: 1em;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.bubbleContainer {
    background-color: white;
}

.passwordReset button:enabled {
    background-color: lawngreen;
}