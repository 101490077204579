/* SavedRepliesDropdown.css */
.dropdownContainer {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}

.dropdownIcon {
    width: 20px;
    height: 20px;
}

.dropdownMenu {
    position: absolute;
    bottom: 100%;
    /* right: 0; */
    width: max-content;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}
