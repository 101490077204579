.bubbleContainer {
	outline: rgb(20,20,20,.8) solid thin;
	margin: 1em;
	padding: .5em;
	box-shadow: 5px 5px 6px 1px rgba(0,0,0,.2);
}
.bubbleContainer > * {
	margin-bottom: .5em;
}
/* .boldText {
    font-weight: 400;
} */