

.signin form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
}

.signin h1 {
    margin-bottom: 1em;
}

.signin h1+p {
    margin-bottom: 1em;
}

.signin input {
    margin: 0.5em 1em 1em;
    width: 90%;
    min-width: 50vw;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.signin button {
    width: auto;
    padding: 0.5em 1em;
    margin: 1em;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.bubbleContainer {
    background-color: white;
}

.signin button:enabled {
    background-color: lawngreen;
}

summary h2 {
    display: inline-block;
    margin-bottom: 0.5em;
}
details * {
    margin-bottom: 1em;
}
details > ::marker {
    font-size: 1.5em;
}