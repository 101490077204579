#autoReplies .bubble {
margin: 2em 0 3em 0;
/* border: 1px grey solid; */
padding: 1em 0.5em;
box-shadow: 0 0 5px #ccc;
}
#autoReplies .bubble p, #autoReplies .bubble span {
    font-size: 0.8rem;
}
#autoReplies input[type="checkbox"] {
    margin-right: 0.2em;
}
#autoReplies .bubble textarea {
    margin-top: 1em;
    margin-bottom: 1em;
    min-height: max-content;
    height: 10em;
}
#autoReplies .bubble input[type="text"] {
    margin-top: 0.5em;
    margin-bottom: 2em;
    width: fit-content;
    min-width: 200px;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #ccc;
    display: block;
}

#autoReplies .keywordInput {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: 2em;
    margin-top: 2em;
}
#autoReplies .keywordInput button {
    display: inline-block;
    margin: 0
    ;
}
#autoReplies .keywordInput input {
    margin: 0 0.5em 0 0;
}