#account .bubble > p {
    margin-bottom: 1em;
    font-size: 0.8rem;
}
#account .bubble input {
    margin-top: 0.5em;
    width: auto;
    min-width: 200px;
    padding: 0.5em  0.3em;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    box-shadow: 0 0 5px #ccc;
}

