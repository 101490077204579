.singleChatInfoPanel {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
}
.singleChatMessage {
    margin-bottom: 0;
    width: fit-content;
    max-width: 70%;
    padding: 0.5em;
    background-color: #f4f3f3;
    word-break: break-word;
    white-space: pre-wrap;
    /*   width: max-content; */
  }
.chatFilerTabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding:0 !important
}
.chatFilerTabActive {
    background-color: lawngreen;
    padding: 0.5em;
    /* border-radius: 0.5em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    cursor: pointer;
    width:50%;
    display: flex;
    justify-content: space-between;
    /* height: 30px; */
}
.chatFilerTabActive span {
    font-weight: 700;
}
.chatFilerTab {
    background-color: #cacaca;
    padding: 0.5em;
    /* border-radius: 0.5em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    cursor: pointer;
    width:50%;
    display: flex;
    justify-content: space-between;
    /* height: 25px; */
}
.chatFilerTab span {
    font-weight: 300;
}
#companyName {
    white-space: pre;
    text-overflow: ellipsis;
    overflow-x: clip;
}
.smallCounterNumber {
    /* background-color: #00d257; */
    /* color: white; */
    /* padding: 0.5em; */
    /* text-align: right; */
}