.leChatWidget * {
/*     all: initial; */
/*     accent-color: auto; */
    align-content: normal;
    align-items: normal;
    align-self: auto;
    alignment-baseline: auto;
    animation-composition: replace;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0s;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-name: none;
    animation-play-state: running;
    animation-range-end: normal;
    animation-range-start: normal;
    animation-timeline: auto;
    animation-timing-function: ease;
    app-region: none;
    appearance: none;
    aspect-ratio: auto;
    backdrop-filter: none;
    backface-visibility: visible;
    background-attachment: scroll;
    background-blend-mode: normal;
    background-clip: border-box;
/*     background-color: rgba(0, 0, 0, 0); */
    background-image: none;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    baseline-shift: 0px;
    block-size: auto;
    /* Adjusted from specific px value to auto */
/*     border-block-end-color: rgb(0, 0, 0); */
    border-block-end-style: none;
    border-block-end-width: 0px;
/*     border-block-start-color: rgb(0, 0, 0); */
    border-block-start-style: none;
    border-block-start-width: 0px;
/*     border-bottom-color: rgb(0, 0, 0); */
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-collapse: separate;
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
/*     border-inline-end-color: rgb(0, 0, 0); */
    border-inline-end-style: none;
    border-inline-end-width: 0px;
/*     border-inline-start-color: rgb(0, 0, 0); */
    border-inline-start-style: none;
    border-inline-start-width: 0px;
/*     border-left-color: rgb(0, 0, 0); */
    border-left-style: none;
    border-left-width: 0px;
/*     border-right-color: rgb(0, 0, 0); */
    border-right-style: none;
    border-right-width: 0px;
    border-start-end-radius: 0px;
    border-start-start-radius: 0px;
/*     border-top-color: rgb(0, 0, 0); */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-style: none;
    border-top-width: 0px;
    bottom: auto;
    box-shadow: none;
/*     box-sizing: content-box; */
    break-after: auto;
    break-before: auto;
    break-inside: auto;
    buffered-rendering: auto;
    caption-side: top;
/*     caret-color: rgb(0, 0, 0); */
    clear: none;
    clip: auto;
    clip-path: none;
    clip-rule: nonzero;
/*     color: rgb(0, 0, 0); */
/*     color-interpolation: srgb; */
/*     color-interpolation-filters: linearrgb; */
/*     color-rendering: auto; */
/*     color-scheme: normal; */
    column-count: auto;
    column-fill: balance;
    column-gap: normal;
/*     column-rule-color: rgb(0, 0, 0); */
    column-rule-style: none;
    column-rule-width: 0px;
    column-span: none;
    column-width: auto;
    contain: none;
    contain-intrinsic-block-size: none;
    contain-intrinsic-height: none;
    contain-intrinsic-inline-size: none;
    contain-intrinsic-width: none;
    container-name: none;
    container-type: normal;
    content: normal;
    content-visibility: visible;
    counter-increment: none;
    counter-reset: none;
    counter-set: none;
    cursor: auto;
    display: block;
    dominant-baseline: auto;
    empty-cells: show;
    fill: rgb(0, 0, 0);
    fill-opacity: 1;
    fill-rule: nonzero;
    filter: none;
    flex-basis: auto;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 1;
    flex-wrap: nowrap;
    float: none;
/*     flood-color: rgb(0, 0, 0); */
    flood-opacity: 1;
/*     font-family: Arial, sans-serif; */
/*     font-feature-settings: normal; */
/*     font-kerning: auto; */
/*     font-optical-sizing: auto; */
/*     font-palette: normal; */
/*     font-size: 16px; */
/*     font-stretch: 100%; */
/*     font-style: normal; */
/*     font-synthesis-small-caps: auto; */
/*     font-synthesis-style: auto; */
/*     font-synthesis-weight: auto; */
/*     font-variant-alternates: normal; */
/*     font-variant-caps: normal; */
/*     font-variant-east-asian: normal; */
/*     font-variant-ligatures: normal; */
/*     font-variant-numeric: normal; */
/*     font-variant-position: normal; */
/*     font-variation-settings: normal; */
/*     font-weight: 400; */
/*     forced-color-adjust: auto; */
    grid-auto-columns: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-column-end: auto;
    grid-column-start: auto;
    grid-row-end: auto;
    grid-row-start: auto;
    grid-template-areas: none;
    grid-template-columns: none;
    grid-template-rows: none;
    height: auto;
    /* Adjusted from specific px value to auto */
    hyphenate-character: auto;
    hyphenate-limit-chars: auto;
    hyphens: manual;
    image-orientation: from-image;
    image-rendering: auto;
    initial-letter: normal;
    inline-size: auto;
    /* Adjusted from specific px value to auto */
    inset-block-end: auto;
    inset-block-start: auto;
    inset-inline-end: auto;
    inset-inline-start: auto;
    isolation: auto;
    justify-content: normal;
    justify-items: normal;
    justify-self: auto;
    left: auto;
    letter-spacing: normal;
/*     lighting-color: rgb(255, 255, 255); */
    line-break: auto;
    line-height: 18.4px;
    /* This value might need adjusting */
    list-style-image: none;
    list-style-position: outside;
    list-style-type: disc;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    marker-end: none;
    marker-mid: none;
    marker-start: none;
    mask-clip: border-box;
    mask-composite: add;
    mask-image: none;
    mask-mode: match-source;
    mask-origin: border-box;
    mask-repeat: repeat;
    mask-size: auto;
    mask-type: luminance;
    math-depth: 0;
    math-shift: normal;
    math-style: normal;
    max-block-size: none;
    max-height: none;
    max-inline-size: none;
    max-width: none;
    min-block-size: 0px;
    min-height: 0px;
    min-inline-size: 0px;
    min-width: 0px;
    mix-blend-mode: normal;
    object-fit: fill;
    object-position: 50% 50%;
    object-view-box: none;
    offset-anchor: auto;
    offset-distance: 0px;
    offset-path: none;
    offset-position: normal;
    offset-rotate: auto 0deg;
    opacity: 1;
    order: 0;
    orphans: 2;
/*     outline-color: rgb(0, 0, 0); */
    outline-offset: 0px;
    outline-style: none;
    outline-width: 0px;
    overflow-anchor: auto;
    overflow-clip-margin: 0px;
    overflow-wrap: normal;
    overflow-x: visible;
    overflow-y: visible;
    overlay: none;
    overscroll-behavior-block: auto;
    overscroll-behavior-inline: auto;
    overscroll-behavior-x: auto;
    overscroll-behavior-y: auto;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-bottom: 0px;
    padding-inline-end: 0px;
    padding-inline-start: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    page: auto;
    page-orientation: none;
    paint-order: normal;
    perspective: none;
    perspective-origin: 50% 50%;
    /* Center */
    pointer-events: auto;
    position: static;
    quotes: auto;
    r: 0px;
    resize: none;
    right: auto;
    rotate: none;
    row-gap: normal;
    ruby-position: over;
    rx: auto;
    ry: auto;
    scale: none;
    scroll-behavior: auto;
    scroll-margin-block-end: 0px;
    scroll-margin-block-start: 0px;
    scroll-margin-bottom: 0px;
    scroll-margin-inline-end: 0px;
    scroll-margin-inline-start: 0px;
    scroll-margin-left: 0px;
    scroll-margin-right: 0px;
    scroll-margin-top: 0px;
    scroll-padding-block-end: auto;
    scroll-padding-block-start: auto;
    scroll-padding-bottom: auto;
    scroll-padding-inline-end: auto;
    scroll-padding-inline-start: auto;
    scroll-padding-left: auto;
    scroll-padding-right: auto;
    scroll-padding-top: auto;
    scroll-snap-align: none;
    scroll-snap-stop: normal;
    scroll-snap-type: none;
    scroll-timeline-axis: block;
    scroll-timeline-name: none;
/*     scrollbar-color: auto; */
    scrollbar-gutter: auto;
    scrollbar-width: auto;
    shape-image-threshold: 0;
    shape-margin: 0px;
    shape-outside: none;
    shape-rendering: auto;
    size: none;
    speak: normal;
/*     stop-color: rgb(0, 0, 0); */
    stop-opacity: 1;
    stroke: none;
    stroke-dasharray: none;
    stroke-dashoffset: 0px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
    stroke-width: 1px;
    tab-size: 8;
    table-layout: auto;
    text-align: start;
    text-align-last: auto;
    text-anchor: start;
    text-combine-upright: none;
/*     text-decoration-color: rgb(0, 0, 0); */
    text-decoration-line: none;
    text-decoration-skip-ink: auto;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
/*     text-emphasis-color: rgb(0, 0, 0); */
    text-emphasis-position: over;
    text-emphasis-style: none;
    text-indent: 0px;
    text-orientation: mixed;
    text-overflow: clip;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-spacing-trim: normal;
    text-transform: none;
    text-underline-offset: auto;
    text-underline-position: auto;
    text-wrap: wrap;
    timeline-scope: none;
    top: auto;
    touch-action: auto;
    transform: none;
    transform-box: view-box;
    transform-origin: 50% 50%;
    /* Center */
    transform-style: flat;
    transition-behavior: normal;
    transition-delay: 0s;
    transition-duration: 0s;
    transition-property: all;
    transition-timing-function: ease;
    translate: none;
    unicode-bidi: normal;
    user-select: auto;
    vector-effect: none;
    vertical-align: baseline;
    view-timeline-axis: block;
    view-timeline-inset: auto;
    view-timeline-name: none;
    view-transition-name: none;
    visibility: visible;
    white-space-collapse: collapse;
    widows: 2;
    width: auto;
    /* Adjusted from specific px value to auto */
    will-change: auto;
    word-break: normal;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    x: 0px;
    y: 0px;
    z-index: auto;
    zoom: 1;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-box-align: stretch;
    -webkit-box-decoration-break: slice;
    -webkit-box-direction: normal;
    -webkit-box-flex: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: start;
    -webkit-box-reflect: none;
/*     -webkit-font-smoothing: auto; */
    -webkit-line-clamp: none;
    -webkit-locale: "en";
    -webkit-mask-box-image-outset: 0;
    -webkit-mask-box-image-repeat: stretch;
    -webkit-mask-box-image-slice: 0 fill;
    -webkit-mask-box-image-source: none;
    -webkit-mask-box-image-width: auto;
    -webkit-mask-position-x: 0%;
    -webkit-mask-position-y: 0%;
    -webkit-perspective-origin-x: none;
    -webkit-perspective-origin-y: none;
/*     -webkit-print-color-adjust: economy; */
    -webkit-rtl-ordering: logical;
/*     -webkit-tap-highlight-color: rgba(0, 0, 0, 0.18); */
    -webkit-text-combine: none;
    -webkit-text-decorations-in-effect: none;
/*     -webkit-text-fill-color: rgb(0, 0, 0); */
    -webkit-text-security: none;
/*     -webkit-text-stroke-color: rgb(0, 0, 0); */
    -webkit-text-stroke-width: 0px;
    -webkit-transform-origin-x: none;
    -webkit-transform-origin-y: none;
    -webkit-transform-origin-z: none;
    -webkit-user-drag: auto;
    -webkit-user-modify: read-only;
    -webkit-border-image: none;
    -webkit-ruby-position: before;
    -webkit-text-orientation: vertical-right;
}