section.signin {
    background: linear-gradient(164deg, #7b98ff, #fdfa1d 50%, #fcb045);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-around;
    padding: 5vh 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.signin form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
}

.signin h1 {
    margin-bottom: 1em;
}

.signin h1+p {
    margin-bottom: 1em;
}

.signin input {
    margin: 0.5em 1em 1em;
    width: 90%;
    min-width: 50vw;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.signin button {
    width: auto;
    padding: 0.5em 1em;
    margin: 1em;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

.bubbleContainer {
    background-color: white;
}

.signin button:enabled {
    background-color: lawngreen;
}