.visitorsTable {
    display: grid;
    grid-template-columns: 5% 20% 20% 10% 10% 15% 5%;
    font-size: .7rem;
    margin-bottom: 1em;
    gap: 5px;
    box-sizing: border-box;
}

.headerP {
    margin-bottom: 2em;
}

.singleLineEllipsisCut {
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
}