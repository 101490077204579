.bubble > p, .bubble > span {
    margin-bottom: 1em;
    font-size: 0.8rem;
}
.bubble input[type="text"] {
    margin-top: 0.5em;
    width: fit-content;
    min-width: 200px;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    box-shadow: 0 0 5px #ccc;
    display: block;
}
button.singleButton {
    display:block;
}
.widgetPreview {
    position: relative;
}
.bubble textarea {
    margin-top: 0.5em;
    width: 100%;
    min-width: 200px;
    padding: 0.5em 0.3em;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    box-shadow: 0 0 5px #ccc;
    display: block;
    resize: none;
}
.grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}
.avatarPreview {
    width: 50px;
}
input[type="color"] {
    margin-bottom: 2em;
}