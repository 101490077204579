* {
  margin: 0;
  font-weight: 300;
  box-sizing: border-box;
}

h1 {
  /*   font-family: 'Italiana', serif; */
  /* font-family: 'Libre Caslon Display', serif; */
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  /* margin: .5em 0; */
}

h2,
h3,
h4,
h5,
h6 {
  /*   font-family: 'Italiana', serif; */
  /* font-family: 'Libre Caslon Display', serif; */
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  /* margin: .5em 0; */
}


.dashboardLayout {
  display: grid;
  grid-template-columns: minmax(4em, 5vw) minmax(700px,1fr);
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
}

aside.sidebar {
  display: inline-block;
  /*   width: 5vw; */
  height: 100vh;
  min-width: 50px;
}

section.chatList {
  display: inline-block;
  width: 250px;
  overflow-y: scroll;
  outline: rgb(20, 20, 20, .8) solid thin;
}

section.chatList>* {
  padding: 0.5em;
}

/* section.chatList>h1 {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

a:link,
a:visited {
  color: #14a9f7;
  text-decoration: none;
}

a:hover {
  color: #14a9f7;
  text-decoration: underline;
}

a:active {
  color: #ff0200;
}

div.chatContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 250px 1fr;
}

section.singleChat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50px;
  left: 35vw;
  /*   overflow-y: scroll; */
  width: 100%;
  height: 100vh;
  margin-left: 1px;
  /* padding: 1em 0.5em; */
  padding-bottom: 1rem;
}

.activeConvo {
  background-color: #f0f0f0;
}

/* 
.singleChatMessage {
  margin-bottom: 0;
  width: fit-content;
  max-width: 70%;
  padding: 0.5em;
  background-color: #f4f3f3;
  word-break: break-all;
  /*   width: max-content; */
/* }   */


.visitorEmail {
  font-size: .9em;
  word-break: break-all;
}

section.homeContainer {
  padding: 1em .5em;
  overflow-y: scroll;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin: 1em auto;
}

button {
  width: auto;
  padding: 0.5em 1em;
  margin: 1em 2em 1em 0;
  background-color: white;
  border: 1px solid grey;
  /* border-radius: 5px; */
  box-shadow: 0 0 5px #ccc;
}

button.cancelButton:enabled {
  background-color: rgb(255 255 0);
}

button.saveButton:enabled {
  background-color: lawngreen;
}

button.dangerButton:enabled {
  /* background-color: rgb(255, 0, 0); */
  background-color: rgb(255 138 138);
}

.uiIcon {
  width: 1.2em;
  margin: 0 0.5em;
  vertical-align: text-bottom;
}

/* decorators */
.outlined {
  outline: rgb(20, 20, 20, .8) solid thin;
}

.underlined {
  border-bottom: 1px solid grey;
}

.tinyText {
  font-size: .7em
}

.italicText {
  font-style: italic
}

.boldText {
  font-weight: 700
}

.bottomSpacer {
  margin-bottom: 1em
}

.greenBullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: lawngreen;
  border-radius: 50%;
  border: 1px solid black;
  right: .5em;
}

.redBullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid black;
  right: .5em;
}


/* alignments */
.textLeftAligned {
  text-align: left
}

.textRightAligned {
  text-align: right
}

.textCentered {
  text-align: center
}
.inlineElement {
  display: inline
}

.messageLeftAligned {
  margin: 1em;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.messageRightAligned {
  margin: 1em;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.inlineFlexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* dimensions */

.max90width {
  max-width: 90%;
}