.leChatWidget code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leChatWidget>* {
  box-sizing: border-box;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 14px;
  box-sizing: border-box;
}
.leChatWidget .chatContainer {
  display:block
}
.leChatWidget textarea {
  background-color: white;
    color: black;
    width: 100%;
    padding: 0.3em;
    border: 1px solid rgb(204, 204, 204);
    height: 27px;
    max-height: 6em;
    overflow: scroll;
    resize: none;
    align-self: center;
}

/*  widget icon styles */
.leChatWidget .widgetIcon {
  box-sizing: border-box;
  /* position: fixed; */
  z-index: 13666;
  /* bottom: 20px;
  right: 20px; */
  width: 50px;
  height: 50px;
  padding: 4px;
  background-color: rgb(20, 20, 20, .8);
  border-radius: 5px;
  box-shadow: 5px 5px 6px 1px rgba(0, 0, 0, .2);
}

/* chat window styles */
.leChatWidget .chatWindow {
  width: 300px;
  /* width: calc(100% - 20px); */
  /* max-height: 400px; */
  /*   overflow-y: scroll;
  overflow-anchor: auto; */
  border: 1px solid rgb(20, 20, 20, .8);
  border-radius: 5px;
  /* position: fixed; */
  z-index: 13666;
  bottom: 80px;
  right: 20px;
  box-shadow: 5px 5px 6px 1px rgba(0, 0, 0, .2);
  background-color: #fff;
}


.leChatWidget .chatWindow header {
  position: relative;
  border-bottom: 1px solid rgb(20, 20, 20, .8);
  color: white;
  background-color: rgb(20, 20, 20, .8);
  padding: 0 0.5em
}


/* .chatWindow header p {
  margin: 1em;
} */


.leChatWidget .chatWindowMessages {
  height: 100%;
  max-height: 200px;
  padding: 1em 0.5em;
  overflow-y: scroll;
  overflow-anchor: auto;
  color: black;
}

.leChatWidget .chatConversation {
  height: auto;
  padding: 0.3em;
  display: flex;
  flex-direction: column;
  /* min-width: 350px; */
}

.leChatWidget .leChatWidgetIcon {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
}

.leChatWidget .chatContainer>header>article {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.2em;
  color: #fff;
}

.leChatWidget .chatContainer>header>article>span.companyAvatrContainer {
  /* width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 0.5em 0 0;
  padding: 2px;
  display: inline-block;
  background-color: white;
  border: 2px solid rgb(198, 31, 31);
  border-radius: 50%;
  overflow-clip-margin: content-box;
  overflow: clip; */
  position: relative;
}

.leChatWidget .chatContainer>header>article>span.companyAvatrContainer>img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 0.5em 0 0;
  padding: 2px;
  display: inline-block;
  background-color: white;
  border: 2px solid rgb(198, 31, 31);
  border-radius: 50%;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.leChatWidget .chatContainer>header>article h1 {
  font-weight: bold;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: inline-block;
  box-sizing: border-box;
  font-size: 1em;
  /* color: #fff;
  background-color: rgb(20, 20, 20, .8); */
  padding: 5px;
  /* position: sticky; */
  top: 0;

}

.leChatWidget .chatConvoItem.visitor {
  text-align: right;
  margin-bottom: 1em;
  display: flex;
  align-items: baseline;
  flex-direction: row-reverse;
}

.leChatWidget .chatConvoItem.visitor .chatConvoItemAvatar {
  margin-left: 0.5em;
}

.leChatWidget .chatConvoItem.visitor .chatConvoItemContentMessage {
  background-color: #85f18a;
  color:black;
  border-radius: 5px;
  padding: 5px;
  color: black;
  box-sizing: border-box;
  max-width: 80%;
  min-width: min-content;
  text-align: right;
  word-break: auto-phrase;
}
.leChatWidget .chatConvoItem.visitor .chatConvoItemContent {
  display: flex;
  justify-content: flex-end;
  width:100%
}

.leChatWidget .chatConvoItem.user {
  text-align: left;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.leChatWidget .chatConvoItem.user .chatConvoItemAvatar {
  margin-right: calc(15px + 0.5em);
}

.leChatWidget .chatConvoItem.user .chatConvoItemContentMessage {
  background-color: #e8e8e8;
  color:black;
  border-radius: 5px;
  padding: 5px;
  color: black;
  box-sizing: border-box;
  max-width: 80%;
  min-width: min-content;
  text-align: left;
  word-break: auto-phrase;
}

.leChatWidget .chatConvoItem.user .chatConvoItemContent {
  display: flex;
  justify-content: flex-start;
  width:100%
}

.leChatWidget img.chatAvatars {
  width: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.leChatWidget .tinyText {
  font-size: .8em
}

.leChatWidget form.askEmailContainer {
  padding: 0 1em 1em;
  color: #fff;
  display: block;
}

.leChatWidget form.askEmailContainer>p.tinyText {
  color: inherit;
}

.leChatWidget form.askEmailContainer input {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-top: 0.6em;
  width: 60%;
  padding: 0.3em;
  display: inline-block;
  background-color: white;
  color: black;
  border-radius: initial;
}

.leChatWidget form.askEmailContainer button {
  width: auto;
  padding: 0.3em;
  margin-left: .5em;
  background-color: white;
  border: 1px solid grey;
  color: black;
  text-align: center;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 14px;
  box-sizing: border-box;
}

.leChatWidget .chatConvoItemContentMessage {
  white-space: pre-wrap;
}

.leChatWidget .poweredBy {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2em;
  padding: 0.3em 0 0.8em 0;
}

.leChatWidget .poweredBy p {
  margin: 0;
  font-size: .8em;
  color: rgb(20, 20, 20, .8);
}

.leChatWidget .poweredBy img {
  height: 1em;
  width: auto;
  display: block;
}

.leChatWidget .poweredBy>a:link,
.leChatWidget .poweredBy>a:visited {
  color: #14a9f7;
  text-decoration: none;
}

.leChatWidget .poweredBy>a:hover {
  color: #14a9f7;
  /*   text-decoration: underline; */
}

.leChatWidget .poweredBy>a:active {
  color: #ff0200;
}



.leChatWidget a:link,
.leChatWidget a:visited {
  color: #14a9f7;
  text-decoration: none;
}

.leChatWidget a:hover {
  color: #14a9f7;
  text-decoration: underline;
}

.leChatWidget a:active {
  color: #ff0200;
}

.leChatWidget .chatWindow .mobileOnly {
  display: none
}

/* company online status */
.connectedOnline {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: lawngreen;
  border-radius: 50%;
  border: 1px solid black;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.connectedOffline {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgb(145, 145, 145);
  border-radius: 50%;
  border: 1px solid black;
  position: absolute;
  right: 5px;
  bottom: 5px;
}



/* mobile screens */
@media all and (max-width: 600px) {

  .leChatWidget * {
    font-size: 16px;
  }

  .leChatWidget .chatWindow {
    width: 100%;
    right: 0;
    bottom: 0;
  }

  .leChatWidget .chatContainer {
    position: relative;
  }

  .leChatWidget .chatWindow .mobileOnly {
    display: block;
    /* position: absolute; */
    top: 10px;
    right: 10px;
    font-weight: 700;
    z-index: 13666;
  }
}