/* .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
} */

.crop-container {
    position: relative;
    width: 100%;
    height: 400px;
    background: #333;
    margin-top: 20px;
}

.cropped-image-container {
    margin-top: 20px;
}

.cropped-image-container img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
}