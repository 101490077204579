.unreadChatsIndicator {
	width: 10px;
	height: 10px;
	background-color: lawngreen;
	border-radius: 50%;
	border: 1px solid green;
	position: absolute;
	top: 0;
	right: .5em;
}
.positioningContext {
	position: relative;
	display: block;
}